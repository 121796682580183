import { create } from 'zustand';

type FeatureFlagsState = {
  featureFlags: FeatureFlags;
  setFeatureFlags: (flags: FeatureFlags) => void;
  clearState: () => void;
};

export type FeatureFlags = Partial<{
  vcd560: boolean;
  vcd478: boolean;
  vcd866: boolean;
}>;

const initialState = {
  featureFlags: {
    vcd560: false,
    vcd478: false,
    vcd866: false
  }
};

export const useFeatureFlagsStore = create<FeatureFlagsState>()((set) => ({
  ...initialState,

  setFeatureFlags: (featureFlags) => {
    set({ featureFlags });
  },

  clearState: () => {
    set({ ...initialState });
  }
}));
