import { Resource } from 'models';
import { FC } from 'react';
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableCellProps,
  HStack,
  Box,
  Link
} from '@chakra-ui/react';
import { downloadS3File, formatShortDate } from 'utils';
import { PRODUCT_LABELS } from 'consts';
import { DownloadIcon } from './assets';
import {
  theme,
  useFailureNotification,
  formatErrorMessages
} from '@vizgen/vizgen-ui';
const colors = theme.colors;

interface DocumentTableProps {
  resource: Resource;
  isCompact: boolean;
}

export const DocumentTable: FC<DocumentTableProps> = ({
  resource,
  isCompact
}) => {
  const { showFailureNotification, closeFailureNotifications } =
    useFailureNotification();
  const {
    product,
    version,
    resourceDate,
    path: { document = '' }
  } = resource;

  const productCellStyles: TableCellProps = isCompact
    ? {
        w: '200px',
        maxW: '200px'
      }
    : {
        w: '256px',
        maxW: '256px'
      };

  const downloadDocument = async () => {
    closeFailureNotifications();
    try {
      await downloadS3File(document);
    } catch (e: any) {
      showFailureNotification(formatErrorMessages(e));
    }
  };

  return (
    <HStack spacing="35px" minW={isCompact ? '400px' : '583px'}>
      <Table size="sm" variant="unstyled" w="100%" mr="auto">
        {isCompact || (
          <Thead>
            <Tr color="content.additional">
              <Th w="256px" p="0 0 8px 0" whiteSpace="nowrap">
                Product
              </Th>
              <Th p="0 32px 8px 0" whiteSpace="nowrap">
                Revision
              </Th>
              <Th p="0 0 8px 0" whiteSpace="nowrap">
                Revision date
              </Th>
            </Tr>
          </Thead>
        )}
        <Tbody>
          <Tr>
            <Td p="0 10px 0 0" isTruncated {...productCellStyles}>
              {PRODUCT_LABELS[product]}
            </Td>
            <Td
              p="0 10px 0 0"
              isTruncated
              w={isCompact ? '64px' : '94px'}
              maxW={isCompact ? '64px' : '94px'}
            >
              {version}
            </Td>
            {isCompact || <Td p="0">{formatShortDate(resourceDate)}</Td>}
          </Tr>
        </Tbody>
      </Table>
      {document && (
        <Link as="button" variant="primary" onClick={downloadDocument}>
          <HStack
            spacing="4px"
            __css={{
              '&:hover path': {
                stroke: colors.content.link.hover
              }
            }}
          >
            <DownloadIcon />
            <Box>Download</Box>
          </HStack>
        </Link>
      )}
    </HStack>
  );
};
