import { FC } from 'react';
import { Box, Button, Heading, HStack, useDisclosure } from '@chakra-ui/react';
import {
  ContainerLayout,
  PanelConfiguration,
  PanelDetailsList,
  Navigation
} from 'components';
import { Link as RouterLink } from 'react-router-dom';
import { useGetPanel } from 'hooks';
import {
  auxProbesSelector,
  isPanelCompletedSelector,
  useCreatePanelStore
} from 'store';
import {
  useGetTissue,
  useGetReference,
  useGetPanelConfiguration,
  useImagingKit
} from 'hooks';
import { downloadCodebook } from 'utils';
import { Link as BrowserLink } from 'react-router-dom';
import { generatePath, useParams } from 'react-router';
import { ConfirmFinalizationModal } from './components';
import { Header } from '../Header';
import { Paths } from 'routes';
import { useFailureNotification, formatErrorMessages } from '@vizgen/vizgen-ui';
import { Panel, PanelOwnershipType } from 'generated/types';

export const SummaryStep: FC = () => {
  useGetPanel();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const panel = useCreatePanelStore((state) => state.panel) as Panel;
  const isCompleted = useCreatePanelStore(isPanelCompletedSelector);
  const auxProbes = useCreatePanelStore(auxProbesSelector);
  const tissue = useGetTissue();
  const reference = useGetReference();
  const panelConfiguration = useGetPanelConfiguration();
  const { panelId } = useParams<{ panelId: string }>();
  const kit = useImagingKit(panel?.genesSummary?.primary || 0);

  const { showFailureNotification, closeFailureNotifications } =
    useFailureNotification();

  const download = async () => {
    closeFailureNotifications();
    try {
      await downloadCodebook(
        panel?.panelName || '',
        panel?.panelId || '',
        panel?.serialNumberTag || ''
      );
    } catch (e) {
      showFailureNotification(formatErrorMessages(e));
    }
  };

  return (
    <>
      <Header currentStep={2} />
      <ContainerLayout>
        <Box maxW="632px">
          <PanelConfiguration items={panelConfiguration} />
          <Heading as="h1" pb="32px">
            Summary
          </Heading>
          <PanelDetailsList
            panel={panel}
            tissue={tissue}
            reference={reference}
            auxProbes={auxProbes}
            matchingKit={kit}
          />
          {isCompleted && (
            <HStack spacing="24px" mb="32px">
              <Button
                variant="primaryHigh"
                w="50%"
                isDisabled={!panel?.hasCodebook && !panel?.serialNumberTag}
                onClick={download}
                data-testid="downloadCodebookButton"
              >
                Download Codebook
              </Button>
              <Button
                variant="primaryGhost"
                w="50%"
                h="48px"
                as={BrowserLink}
                target="_blank"
                to={generatePath(Paths.PrintablePanel, {
                  panelType: PanelOwnershipType.proprietary,
                  panelId: panel?.panelId
                })}
                data-testid="openPrintableFileLink"
              >
                Open Printable File
              </Button>
            </HStack>
          )}
        </Box>
      </ContainerLayout>
      <Navigation
        leftButton={
          isCompleted ? undefined : (
            <Button
              as={RouterLink}
              to={generatePath(Paths.CreatePanel_AddGenes, { panelId })}
              variant="ghost"
              px="5"
              data-testid="backLink"
            >
              Back
            </Button>
          )
        }
        rightButton={
          isCompleted ? (
            <Button
              as={BrowserLink}
              to={generatePath(Paths.NewQuoteForPanel, { panelId })}
              px="8"
              variant="secondarySolid"
              data-testid="requestQuoteLink"
            >
              Request Quote
            </Button>
          ) : (
            <Button
              onClick={() => {
                closeFailureNotifications();
                onOpen();
              }}
              px="8"
              variant="secondarySolid"
              data-testid="finalizePanelButton"
            >
              Finalize Panel
            </Button>
          )
        }
      />
      <ConfirmFinalizationModal
        kit={kit}
        isVisible={isOpen}
        onClose={onClose}
      />
    </>
  );
};
