/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreatePanelInput = {
  panelId: string;
  tissueId: string;
  transcriptomeId: string;
  userId?: string | null;
  panelName: string;
  isCompleted?: boolean | null;
  statusMessage?: string | null;
  comment?: string | null;
  isProprietary?: boolean | null;
  geneSortMethod?: GeneSortMethod | null;
  geneSetSortMethod?: GeneSetSortMethod | null;
  geneSets?: Array<string> | null;
  geneSetS3Key?: string | null;
  geneSetVersion?: string | null;
  geneSetCommitId?: string | null;
  hasCodebook?: boolean | null;
  owner?: string | null;
  application?: string | null;
  platform?: string | null;
  ownershipType?: PanelOwnershipType | null;
};

export enum GeneSortMethod {
  alphabetic = 'alphabetic',
  geneAbundanceAscending = 'geneAbundanceAscending',
  geneAbundanceDescending = 'geneAbundanceDescending',
  jaccardSimilarity = 'jaccardSimilarity'
}

export enum GeneSetSortMethod {
  numMatchingAscending = 'numMatchingAscending',
  numMatchingDescending = 'numMatchingDescending',
  proportionOfRepresentationAscending = 'proportionOfRepresentationAscending',
  proportionOfRepresentationDescending = 'proportionOfRepresentationDescending',
  jaccardSimilarity = 'jaccardSimilarity'
}

export enum PanelOwnershipType {
  promoted = 'promoted',
  proprietary = 'proprietary'
}

export type ModelPanelConditionInput = {
  tissueId?: ModelIDInput | null;
  transcriptomeId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  panelName?: ModelStringInput | null;
  isCompleted?: ModelBooleanInput | null;
  isLocked?: ModelBooleanInput | null;
  serialNumberTag?: ModelStringInput | null;
  statusMessage?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  isProprietary?: ModelBooleanInput | null;
  deleted?: ModelBooleanInput | null;
  hasCodebook?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
  application?: ModelStringInput | null;
  platform?: ModelStringInput | null;
  ownershipType?: ModelPanelOwnershipTypeInput | null;
  approver1?: ModelStringInput | null;
  approver2?: ModelStringInput | null;
  uploader?: ModelStringInput | null;
  and?: Array<ModelPanelConditionInput | null> | null;
  or?: Array<ModelPanelConditionInput | null> | null;
  not?: ModelPanelConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null'
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelPanelOwnershipTypeInput = {
  eq?: PanelOwnershipType | null;
  ne?: PanelOwnershipType | null;
};

export type Panel = {
  __typename: 'Panel';
  panelId: string;
  tissueId: string;
  transcriptomeId: string;
  userId?: string | null;
  panelName: string;
  isCompleted?: boolean | null;
  isLocked?: boolean | null;
  serialNumberTag?: string | null;
  statusMessage?: string | null;
  comment?: string | null;
  isProprietary?: boolean | null;
  deleted?: boolean | null;
  genes?: ModelPanelTranscriptConnection | null;
  genesSummary?: PanelGenesSummary | null;
  hasCodebook?: boolean | null;
  owner?: string | null;
  application?: string | null;
  platform?: string | null;
  ownershipType?: PanelOwnershipType | null;
  approver1?: string | null;
  approver2?: string | null;
  uploader?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelPanelTranscriptConnection = {
  __typename: 'ModelPanelTranscriptConnection';
  items: Array<PanelTranscript | null>;
  nextToken?: string | null;
};

export type PanelTranscript = {
  __typename: 'PanelTranscript';
  panelId: string;
  transcriptId: string;
  parentPanelId?: string | null;
  targetRegionQty?: number | null;
  abundance?: number | null;
  refAbundance?: number | null;
  geneName?: string | null;
  geneId?: string | null;
  panel?: Panel | null;
  transcriptomeId: string;
  transcript?: Transcript | null;
  tissueId: string;
  transcriptTargetRegions?: TranscriptTargetRegions | null;
  userSpecifiedName: string;
  status: PanelTranscriptStatus;
  isIncluded?: boolean | null;
  statusMessage?: string | null;
  comment?: string | null;
  type: PanelTranscriptType;
  source: PanelTranscriptSource;
  auxProbes?: PanelTranscriptSequentialAuxProbes | null;
  synonyms?: Array<GeneSymbolSynonym> | null;
  duplicated?: boolean | null;
  owner?: string | null;
  isLocked?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type Transcript = {
  __typename: 'Transcript';
  transcriptId: string;
  ensembleTranscriptId: string;
  ensembleTranscriptRev: number;
  transcriptomeId: string;
  geneId: string;
  geneName: string;
  geneBioType: string;
  geneIdRev: number;
  source: string;
  sequence: string;
  description?: string | null;
};

export type TranscriptTargetRegions = {
  __typename: 'TranscriptTargetRegions';
  transcriptId: string;
  tissueId: string;
  transcriptomeId: string;
  fpkm?: number | null;
  targetRegionQty?: number | null;
  minimumGeneSpecificity?: number | null;
  minimumIsoformSpecificity?: number | null;
  minimumGC?: number | null;
  maximumGC?: number | null;
  minimumTm?: number | null;
  maximumTm?: number | null;
  overlap?: number | null;
  owner?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum PanelTranscriptStatus {
  calculating = 'calculating',
  not_found = 'not_found',
  untargetable = 'untargetable',
  insufficient_tr = 'insufficient_tr',
  targetable = 'targetable'
}

export enum PanelTranscriptType {
  primary = 'primary',
  sequential = 'sequential'
}

export enum PanelTranscriptSource {
  C = 'C',
  P = 'P',
  E = 'E'
}

export enum PanelTranscriptSequentialAuxProbes {
  Aux4 = 'Aux4',
  Aux5 = 'Aux5',
  Aux6 = 'Aux6',
  Aux7 = 'Aux7',
  Aux8 = 'Aux8',
  Aux9 = 'Aux9'
}

export type GeneSymbolSynonym = {
  __typename: 'GeneSymbolSynonym';
  geneSymbol: string;
  score: number;
  status: PanelTranscriptStatus;
  targetRegionQty?: number | null;
};

export type PanelGenesSummary = {
  __typename: 'PanelGenesSummary';
  totalNumber?: number | null;
  primary?: number | null;
  sequential?: number | null;
  totalAbundance?: number | null;
};

export type Tissue = {
  __typename: 'Tissue';
  tissueId: string;
  transcriptomeId: string;
  name: string;
  source?: string | null;
  description: string;
  enabled?: boolean | null;
  addedAt?: string | null;
};

export type Transcriptome = {
  __typename: 'Transcriptome';
  transcriptomeId: string;
  name: string;
  scientificName: string;
  description: string;
  species: string;
  source?: string | null;
  genomeVersion?: string | null;
  referenceLink?: string | null;
  myGeneInfoTaxId: string;
};

export type UpdatePanelInput = {
  panelId: string;
  tissueId?: string | null;
  transcriptomeId?: string | null;
  userId?: string | null;
  panelName?: string | null;
  isCompleted?: boolean | null;
  statusMessage?: string | null;
  comment?: string | null;
  deleted?: boolean | null;
  isProprietary?: boolean | null;
  geneSortMethod?: GeneSortMethod | null;
  geneSetSortMethod?: GeneSetSortMethod | null;
  geneSets?: Array<string> | null;
  geneSetS3Key?: string | null;
  geneSetVersion?: string | null;
  geneSetCommitId?: string | null;
  hasCodebook?: boolean | null;
  owner?: string | null;
  application?: string | null;
  platform?: string | null;
  ownershipType?: PanelOwnershipType | null;
};

export type CreatePanelTranscriptsFromGenesInput = {
  panelId: string;
  tissueId: string;
  transcriptomeId: string;
  geneInput: Array<GeneInput>;
};

export type GeneInput = {
  geneIdentifier: string;
  fpkm?: number | null;
  comment?: string | null;
};

export type CreatePanelTranscriptBatch = {
  __typename: 'CreatePanelTranscriptBatch';
  input: Array<PanelTranscriptCreateData>;
  run?: Array<PanelTranscript> | null;
};

export type PanelTranscriptCreateData = {
  __typename: 'PanelTranscriptCreateData';
  panelId: string;
  transcriptId: string;
  tissueId: string;
  transcriptomeId: string;
  geneName?: string | null;
  userSpecifiedName: string;
  status: PanelTranscriptStatus;
  targetRegionQty?: number | null;
  abundance?: number | null;
  comment?: string | null;
  type: PanelTranscriptType;
  source: PanelTranscriptSource;
  owner: string;
  createdAt: string;
  updatedAt: string;
};

export type PromotedPanelTranscripts = {
  __typename: 'PromotedPanelTranscripts';
  items?: Array<PanelTranscript | null> | null;
  nextToken?: string | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelPanelTranscriptFilterInput = {
  panelId?: ModelIDInput | null;
  transcriptId?: ModelStringInput | null;
  parentPanelId?: ModelIDInput | null;
  targetRegionQty?: ModelIntInput | null;
  abundance?: ModelFloatInput | null;
  refAbundance?: ModelFloatInput | null;
  geneName?: ModelStringInput | null;
  geneId?: ModelStringInput | null;
  transcriptomeId?: ModelIDInput | null;
  tissueId?: ModelIDInput | null;
  userSpecifiedName?: ModelStringInput | null;
  status?: ModelPanelTranscriptStatusInput | null;
  isIncluded?: ModelBooleanInput | null;
  statusMessage?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  type?: ModelPanelTranscriptTypeInput | null;
  source?: ModelPanelTranscriptSourceInput | null;
  auxProbes?: ModelPanelTranscriptSequentialAuxProbesInput | null;
  duplicated?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
  isLocked?: ModelBooleanInput | null;
  and?: Array<ModelPanelTranscriptFilterInput | null> | null;
  or?: Array<ModelPanelTranscriptFilterInput | null> | null;
  not?: ModelPanelTranscriptFilterInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelPanelTranscriptStatusInput = {
  eq?: PanelTranscriptStatus | null;
  ne?: PanelTranscriptStatus | null;
};

export type ModelPanelTranscriptTypeInput = {
  eq?: PanelTranscriptType | null;
  ne?: PanelTranscriptType | null;
};

export type ModelPanelTranscriptSourceInput = {
  eq?: PanelTranscriptSource | null;
  ne?: PanelTranscriptSource | null;
};

export type ModelPanelTranscriptSequentialAuxProbesInput = {
  eq?: PanelTranscriptSequentialAuxProbes | null;
  ne?: PanelTranscriptSequentialAuxProbes | null;
};

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type ModelQuoteFilterInput = {
  quoteId?: ModelIDInput | null;
  totalGenes?: ModelFloatInput | null;
  totalExtras?: ModelFloatInput | null;
  Status?: ModelQuoteStatusInput | null;
  statusChangedAt?: ModelStringInput | null;
  trackingNumber?: ModelStringInput | null;
  deliveryAddress?: ModelStringInput | null;
  billingAddress?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  totalPrice?: ModelFloatInput | null;
  userId?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelQuoteFilterInput | null> | null;
  or?: Array<ModelQuoteFilterInput | null> | null;
  not?: ModelQuoteFilterInput | null;
};

export type ModelQuoteStatusInput = {
  eq?: QuoteStatus | null;
  ne?: QuoteStatus | null;
};

export enum QuoteStatus {
  Submitted = 'Submitted',
  Confirmed = 'Confirmed'
}

export type ModelQuoteConnection = {
  __typename: 'ModelQuoteConnection';
  items: Array<Quote | null>;
  nextToken?: string | null;
};

export type Quote = {
  __typename: 'Quote';
  quoteId: string;
  totalGenes?: number | null;
  totalExtras?: number | null;
  Status: QuoteStatus;
  statusChangedAt?: string | null;
  trackingNumber?: string | null;
  deliveryAddress?: string | null;
  billingAddress?: string | null;
  comment?: string | null;
  totalPrice?: number | null;
  userId: string;
  feedback?: ModelUserFeedbackConnection | null;
  quotepositions?: ModelQuotePositionConnection | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelUserFeedbackConnection = {
  __typename: 'ModelUserFeedbackConnection';
  items: Array<UserFeedback | null>;
  nextToken?: string | null;
};

export type UserFeedback = {
  __typename: 'UserFeedback';
  feedbackId: string;
  issueType: string;
  quoteId: string;
  description?: string | null;
  feedbackStatus?: string | null;
  userId: string;
  quote?: Quote | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelQuotePositionConnection = {
  __typename: 'ModelQuotePositionConnection';
  items: Array<QuotePosition | null>;
  nextToken?: string | null;
};

export type QuotePosition = {
  __typename: 'QuotePosition';
  quoteId: string;
  quotePositionId: string;
  panelId?: string | null;
  extraId?: string | null;
  quantity: number;
  cartridgeId?: string | null;
  listPricePerItem: number;
  pricePerPosition: number;
  panel?: Panel | null;
  extra?: Extra | null;
  cartridge?: Cartridge | null;
  quote?: Quote | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type Extra = {
  __typename: 'Extra';
  extraId: string;
  extraType: string;
  extraName: string;
  description?: string | null;
  isAntibodyKit: boolean;
  auxProbesUsed?: string | null;
};

export type Cartridge = {
  __typename: 'Cartridge';
  cartridgeId: string;
  barcode: string;
  description?: string | null;
  expirationDate: string;
  reagentCartridgeMapNumber: number;
  cartridgeLotNumber: number;
  isUsed: boolean;
  isAvailable: boolean;
  isCreated: boolean;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePanelTranscriptInput = {
  panelId: string;
  transcriptId: string;
  parentPanelId?: string | null;
  targetRegionQty?: number | null;
  abundance?: number | null;
  refAbundance?: number | null;
  geneName?: string | null;
  geneId?: string | null;
  transcriptomeId?: string | null;
  transcript?: TranscriptInput | null;
  tissueId?: string | null;
  transcriptTargetRegions?: TranscriptTargetRegionsInput | null;
  userSpecifiedName?: string | null;
  status?: PanelTranscriptStatus | null;
  isIncluded?: boolean | null;
  statusMessage?: string | null;
  comment?: string | null;
  type?: PanelTranscriptType | null;
  source?: PanelTranscriptSource | null;
  auxProbes?: PanelTranscriptSequentialAuxProbes | null;
  synonyms?: Array<GeneSymbolSynonymInput> | null;
  duplicated?: boolean | null;
  owner?: string | null;
  isLocked?: boolean | null;
};

export type TranscriptInput = {
  transcriptId: string;
  ensembleTranscriptId: string;
  ensembleTranscriptRev: number;
  transcriptomeId: string;
  geneId: string;
  geneName: string;
  geneBioType: string;
  geneIdRev: number;
  source: string;
  sequence: string;
  description?: string | null;
};

export type TranscriptTargetRegionsInput = {
  transcriptId: string;
  tissueId: string;
  transcriptomeId: string;
  fpkm?: number | null;
  targetRegionQty?: number | null;
  minimumGeneSpecificity?: number | null;
  minimumIsoformSpecificity?: number | null;
  minimumGC?: number | null;
  maximumGC?: number | null;
  minimumTm?: number | null;
  maximumTm?: number | null;
  overlap?: number | null;
  owner?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type GeneSymbolSynonymInput = {
  geneSymbol: string;
  score: number;
  status: PanelTranscriptStatus;
  targetRegionQty?: number | null;
};

export type ModelPanelTranscriptConditionInput = {
  parentPanelId?: ModelIDInput | null;
  targetRegionQty?: ModelIntInput | null;
  abundance?: ModelFloatInput | null;
  refAbundance?: ModelFloatInput | null;
  geneName?: ModelStringInput | null;
  geneId?: ModelStringInput | null;
  transcriptomeId?: ModelIDInput | null;
  tissueId?: ModelIDInput | null;
  userSpecifiedName?: ModelStringInput | null;
  status?: ModelPanelTranscriptStatusInput | null;
  isIncluded?: ModelBooleanInput | null;
  statusMessage?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  type?: ModelPanelTranscriptTypeInput | null;
  source?: ModelPanelTranscriptSourceInput | null;
  auxProbes?: ModelPanelTranscriptSequentialAuxProbesInput | null;
  duplicated?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
  isLocked?: ModelBooleanInput | null;
  and?: Array<ModelPanelTranscriptConditionInput | null> | null;
  or?: Array<ModelPanelTranscriptConditionInput | null> | null;
  not?: ModelPanelTranscriptConditionInput | null;
};

export type DeletePanelTranscriptInput = {
  panelId: string;
  transcriptId: string;
};

export type CreateQuoteInput = {
  quoteId: string;
  totalGenes?: number | null;
  totalExtras?: number | null;
  Status: QuoteStatus;
  statusChangedAt?: string | null;
  trackingNumber?: string | null;
  deliveryAddress?: string | null;
  billingAddress?: string | null;
  comment?: string | null;
  totalPrice?: number | null;
  userId: string;
  owner?: string | null;
};

export type ModelQuoteConditionInput = {
  totalGenes?: ModelFloatInput | null;
  totalExtras?: ModelFloatInput | null;
  Status?: ModelQuoteStatusInput | null;
  statusChangedAt?: ModelStringInput | null;
  trackingNumber?: ModelStringInput | null;
  deliveryAddress?: ModelStringInput | null;
  billingAddress?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  totalPrice?: ModelFloatInput | null;
  userId?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelQuoteConditionInput | null> | null;
  or?: Array<ModelQuoteConditionInput | null> | null;
  not?: ModelQuoteConditionInput | null;
};

export type CreateQuotePositionInput = {
  quoteId: string;
  quotePositionId: string;
  panelId?: string | null;
  extraId?: string | null;
  quantity: number;
  cartridgeId?: string | null;
  listPricePerItem: number;
  pricePerPosition: number;
  extra?: ExtraInput | null;
  owner?: string | null;
};

export type ExtraInput = {
  extraId: string;
  extraType: string;
  extraName: string;
  description?: string | null;
  isAntibodyKit: boolean;
  auxProbesUsed?: string | null;
};

export type ModelQuotePositionConditionInput = {
  panelId?: ModelIDInput | null;
  extraId?: ModelIDInput | null;
  quantity?: ModelIntInput | null;
  cartridgeId?: ModelIDInput | null;
  listPricePerItem?: ModelFloatInput | null;
  pricePerPosition?: ModelFloatInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelQuotePositionConditionInput | null> | null;
  or?: Array<ModelQuotePositionConditionInput | null> | null;
  not?: ModelQuotePositionConditionInput | null;
};

export type CreateAddressInput = {
  addressId: string;
  address: string;
  addressType: AddressType;
  userId: string;
  owner?: string | null;
};

export enum AddressType {
  billing = 'billing',
  shipping = 'shipping'
}

export type ModelAddressConditionInput = {
  address?: ModelStringInput | null;
  addressType?: ModelAddressTypeInput | null;
  userId?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelAddressConditionInput | null> | null;
  or?: Array<ModelAddressConditionInput | null> | null;
  not?: ModelAddressConditionInput | null;
};

export type ModelAddressTypeInput = {
  eq?: AddressType | null;
  ne?: AddressType | null;
};

export type Address = {
  __typename: 'Address';
  addressId: string;
  address: string;
  addressType: AddressType;
  userId: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelAddressFilterInput = {
  addressId?: ModelIDInput | null;
  address?: ModelStringInput | null;
  addressType?: ModelAddressTypeInput | null;
  userId?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelAddressFilterInput | null> | null;
  or?: Array<ModelAddressFilterInput | null> | null;
  not?: ModelAddressFilterInput | null;
};

export type ModelAddressConnection = {
  __typename: 'ModelAddressConnection';
  items: Array<Address | null>;
  nextToken?: string | null;
};

export type DeleteAddressInput = {
  addressId: string;
};

export type ModelPanelConnection = {
  __typename: 'ModelPanelConnection';
  items?: Array<Panel> | null;
  nextToken?: string | null;
};

export type CreateExogenousSequenceInput = {
  id?: string | null;
  name: string;
  sequence: string;
  fpkm: number;
  owner?: string | null;
};

export type ModelExogenousSequenceConditionInput = {
  name?: ModelStringInput | null;
  sequence?: ModelStringInput | null;
  fpkm?: ModelFloatInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelExogenousSequenceConditionInput | null> | null;
  or?: Array<ModelExogenousSequenceConditionInput | null> | null;
  not?: ModelExogenousSequenceConditionInput | null;
};

export type ExogenousSequence = {
  __typename: 'ExogenousSequence';
  id: string;
  name: string;
  sequence: string;
  fpkm: number;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelExogenousSequenceFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  sequence?: ModelStringInput | null;
  fpkm?: ModelFloatInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelExogenousSequenceFilterInput | null> | null;
  or?: Array<ModelExogenousSequenceFilterInput | null> | null;
  not?: ModelExogenousSequenceFilterInput | null;
};

export type ModelExogenousSequenceConnection = {
  __typename: 'ModelExogenousSequenceConnection';
  items: Array<ExogenousSequence | null>;
  nextToken?: string | null;
};

export type ModelPanelFilterInput = {
  panelId?: ModelIDInput | null;
  tissueId?: ModelIDInput | null;
  transcriptomeId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  panelName?: ModelStringInput | null;
  isCompleted?: ModelBooleanInput | null;
  isLocked?: ModelBooleanInput | null;
  serialNumberTag?: ModelStringInput | null;
  statusMessage?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  isProprietary?: ModelBooleanInput | null;
  deleted?: ModelBooleanInput | null;
  hasCodebook?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
  application?: ModelStringInput | null;
  platform?: ModelStringInput | null;
  ownershipType?: ModelPanelOwnershipTypeInput | null;
  approver1?: ModelStringInput | null;
  approver2?: ModelStringInput | null;
  uploader?: ModelStringInput | null;
  and?: Array<ModelPanelFilterInput | null> | null;
  or?: Array<ModelPanelFilterInput | null> | null;
  not?: ModelPanelFilterInput | null;
};

export type ModelFileResourceFilterInput = {
  resourceId?: ModelStringInput | null;
  description?: ModelStringInput | null;
  product?: ModelStringInput | null;
  category?: ModelStringInput | null;
  version?: ModelStringInput | null;
  resourceDate?: ModelStringInput | null;
  isFeatured?: ModelBooleanInput | null;
  isNew?: ModelBooleanInput | null;
  path?: ModelStringInput | null;
  isHidden?: ModelBooleanInput | null;
  and?: Array<ModelFileResourceFilterInput | null> | null;
  or?: Array<ModelFileResourceFilterInput | null> | null;
  not?: ModelFileResourceFilterInput | null;
};

export type ModelFileResourceConnection = {
  __typename: 'ModelFileResourceConnection';
  items: Array<FileResource | null>;
  nextToken?: string | null;
};

export type FileResource = {
  __typename: 'FileResource';
  resourceId: string;
  description: string;
  product: string;
  category: string;
  version: string;
  resourceDate: string;
  isFeatured: boolean;
  isNew: boolean;
  path: string;
  isHidden: boolean;
  createdAt: string;
  updatedAt: string;
};

export type UserProps = {
  __typename: 'UserProps';
  userId: string;
  isEnabled: boolean;
  email: string;
  emailVerified: boolean;
  accountStatus: string;
  name: string;
  familyName: string;
  countryId?: string | null;
  countryName?: string | null;
  regionId?: string | null;
  regionName?: string | null;
  userType: UserType;
  regionalDistributor?: Distributor | null;
  assignedDistributorId?: number | null;
  assignedDistributor?: Distributor | null;
  organization?: string | null;
  laboratory?: string | null;
  approvalRequestId?: string | null;
};

export enum UserType {
  MERSCOPE = 'MERSCOPE',
  VizgenLab = 'VizgenLab',
  RnD = 'RnD'
}

export type Distributor = {
  __typename: 'Distributor';
  distributorId: number;
  name: string;
  email: string;
};

export type UserPropsInput = {
  userId?: string | null;
  name?: string | null;
  familyName?: string | null;
  countryId?: string | null;
  regionId?: string | null;
  userType?: UserType | null;
  assignedDistributorId?: number | null;
  organization?: string | null;
  laboratory?: string | null;
};

export type Country = {
  __typename: 'Country';
  countryId: string;
  name: string;
  totalRegions: number;
};

export type Region = {
  __typename: 'Region';
  countryId: string;
  regionId: string;
  country: Country;
  distributor?: Distributor | null;
  name: string;
};

export type ModelInstrumentConnection = {
  __typename: 'ModelInstrumentConnection';
  items?: Array<Instrument> | null;
  nextToken?: string | null;
};

export type Instrument = {
  __typename: 'Instrument';
  uniqueId: string;
  serialNumber: string;
  status: string;
  lastIpAddress?: string | null;
  lastStatusUpdated: string;
  admins: Array<UserProps>;
  viewers: Array<UserProps>;
  viewersTotal: number;
  whoAmI: string;
};

export type InstrumentUserInput = {
  instrumentUniqueId: string;
  userEmail: string;
  role: InstrumentUserRole;
};

export enum InstrumentUserRole {
  Admin = 'Admin',
  Viewer = 'Viewer'
}

export type GeneSetCollection = {
  __typename: 'GeneSetCollection';
  geneSetS3Key?: string | null;
  geneSetVersion: string;
  geneSetCommitId: string;
};

export type PanelUserView = {
  __typename: 'PanelUserView';
  userId: string;
  panelId: string;
  geneSortMethod?: GeneSortMethod | null;
  geneSetSortMethod?: GeneSetSortMethod | null;
  geneSets?: Array<string> | null;
  geneSetS3Key?: string | null;
  geneSetVersion?: Array<string> | null;
  geneSetCommitId?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type CreatePanelUserViewInput = {
  userId: string;
  panelId: string;
  geneSortMethod?: GeneSortMethod | null;
  geneSetSortMethod?: GeneSetSortMethod | null;
  geneSets?: Array<string> | null;
  geneSetS3Key?: string | null;
  geneSetVersion?: Array<string> | null;
  geneSetCommitId?: Array<string> | null;
};

export type ModelPanelUserViewConditionInput = {
  geneSortMethod?: ModelGeneSortMethodInput | null;
  geneSetSortMethod?: ModelGeneSetSortMethodInput | null;
  geneSets?: ModelStringInput | null;
  geneSetS3Key?: ModelStringInput | null;
  geneSetVersion?: ModelStringInput | null;
  geneSetCommitId?: ModelStringInput | null;
  and?: Array<ModelPanelUserViewConditionInput | null> | null;
  or?: Array<ModelPanelUserViewConditionInput | null> | null;
  not?: ModelPanelUserViewConditionInput | null;
};

export type ModelGeneSortMethodInput = {
  eq?: GeneSortMethod | null;
  ne?: GeneSortMethod | null;
};

export type ModelGeneSetSortMethodInput = {
  eq?: GeneSetSortMethod | null;
  ne?: GeneSetSortMethod | null;
};

export type UpdatePanelUserViewInput = {
  userId: string;
  panelId: string;
  geneSortMethod?: GeneSortMethod | null;
  geneSetSortMethod?: GeneSetSortMethod | null;
  geneSets?: Array<string> | null;
  geneSetS3Key?: string | null;
  geneSetVersion?: Array<string> | null;
  geneSetCommitId?: Array<string> | null;
};

export type DeletePanelTranscriptsBatchInput = {
  panelId: string;
  transcriptId: string;
};

export type DeletePanelTranscriptsBatchOutput = {
  __typename: 'DeletePanelTranscriptsBatchOutput';
  panelId: string;
  transcriptId: string;
};

export type CreatePanelMutationVariables = {
  input: CreatePanelInput;
  condition?: ModelPanelConditionInput | null;
};

export type CreatePanelMutation = {
  createPanel?: {
    __typename: 'Panel';
    panelId: string;
    tissueId: string;
    transcriptomeId: string;
    userId?: string | null;
    panelName: string;
    isCompleted?: boolean | null;
    statusMessage?: string | null;
    comment?: string | null;
    genesSummary?: {
      __typename: 'PanelGenesSummary';
      totalNumber?: number | null;
      primary?: number | null;
      sequential?: number | null;
      totalAbundance?: number | null;
    } | null;
    hasCodebook?: boolean | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    serialNumberTag?: string | null;
    isProprietary?: boolean | null;
    ownershipType?: PanelOwnershipType | null;
    genes?: {
      __typename: 'ModelPanelTranscriptConnection';
      nextToken?: string | null;
    } | null;
  } | null;
};

export type GetPanelQueryVariables = {
  panelId: string;
};

export type GetPanelQuery = {
  getPanel?: {
    __typename: 'Panel';
    panelId: string;
    tissueId: string;
    transcriptomeId: string;
    userId?: string | null;
    panelName: string;
    isCompleted?: boolean | null;
    comment?: string | null;
    genesSummary?: {
      __typename: 'PanelGenesSummary';
      totalNumber?: number | null;
      primary?: number | null;
      sequential?: number | null;
      totalAbundance?: number | null;
    } | null;
    hasCodebook?: boolean | null;
    updatedAt: string;
    owner?: string | null;
    serialNumberTag?: string | null;
    isProprietary?: boolean | null;
    ownershipType?: PanelOwnershipType | null;
  } | null;
};

export type ListTissuesQueryVariables = {};

export type ListTissuesQuery = {
  listTissues?: Array<{
    __typename: 'Tissue';
    tissueId: string;
    transcriptomeId: string;
    name: string;
    enabled?: boolean | null;
  }> | null;
};

export type ListTranscriptomesQueryVariables = {
  transcriptomeIds?: Array<string> | null;
};

export type ListTranscriptomesQuery = {
  listTranscriptomes?: Array<{
    __typename: 'Transcriptome';
    transcriptomeId: string;
    name: string;
    scientificName: string;
  }> | null;
};

export type UpdatePanelMutationVariables = {
  input: UpdatePanelInput;
  condition?: ModelPanelConditionInput | null;
};

export type UpdatePanelMutation = {
  updatePanel?: {
    __typename: 'Panel';
    panelId: string;
    tissueId: string;
    transcriptomeId: string;
    userId?: string | null;
    panelName: string;
    isCompleted?: boolean | null;
    statusMessage?: string | null;
    comment?: string | null;
    genesSummary?: {
      __typename: 'PanelGenesSummary';
      totalNumber?: number | null;
      primary?: number | null;
      sequential?: number | null;
      totalAbundance?: number | null;
    } | null;
    hasCodebook?: boolean | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    serialNumberTag?: string | null;
    isProprietary?: boolean | null;
    ownershipType?: PanelOwnershipType | null;
    genes?: {
      __typename: 'ModelPanelTranscriptConnection';
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreatePanelTranscriptsFromGenesMutationVariables = {
  input: CreatePanelTranscriptsFromGenesInput;
};

export type CreatePanelTranscriptsFromGenesMutation = {
  createPanelTranscriptsFromGenes: Array<{
    __typename: 'CreatePanelTranscriptBatch';
    run?: Array<{
      __typename: 'PanelTranscript';
      panelId: string;
      transcriptId: string;
      parentPanelId?: string | null;
      targetRegionQty?: number | null;
      abundance?: number | null;
      refAbundance?: number | null;
      geneName?: string | null;
      transcriptomeId: string;
      tissueId: string;
      userSpecifiedName: string;
      isLocked?: boolean | null;
      isIncluded?: boolean | null;
      status: PanelTranscriptStatus;
      source: PanelTranscriptSource;
      statusMessage?: string | null;
      comment?: string | null;
      type: PanelTranscriptType;
      duplicated?: boolean | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      synonyms?: Array<{
        __typename: 'GeneSymbolSynonym';
        geneSymbol: string;
        score: number;
        status: PanelTranscriptStatus;
        targetRegionQty?: number | null;
      }> | null;
      geneId?: string | null;
    }> | null;
  }>;
};

export type ListPromotedPanelTranscriptsQueryVariables = {
  promotedPanelId: string;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListPromotedPanelTranscriptsQuery = {
  listPromotedPanelTranscripts?: {
    __typename: 'PromotedPanelTranscripts';
    items?: Array<{
      __typename: 'PanelTranscript';
      panelId: string;
      transcriptId: string;
      parentPanelId?: string | null;
      targetRegionQty?: number | null;
      abundance?: number | null;
      refAbundance?: number | null;
      geneName?: string | null;
      transcriptomeId: string;
      tissueId: string;
      userSpecifiedName: string;
      isLocked?: boolean | null;
      status: PanelTranscriptStatus;
      isIncluded?: boolean | null;
      statusMessage?: string | null;
      comment?: string | null;
      type: PanelTranscriptType;
      source: PanelTranscriptSource;
      auxProbes?: PanelTranscriptSequentialAuxProbes | null;
      duplicated?: boolean | null;
      createdAt: string;
      updatedAt: string;
      geneId?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ListPanelTranscriptsQueryVariables = {
  panelId?: string | null;
  transcriptId?: ModelStringKeyConditionInput | null;
  filter?: ModelPanelTranscriptFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListPanelTranscriptsQuery = {
  listPanelTranscripts?: {
    __typename: 'ModelPanelTranscriptConnection';
    items: Array<{
      __typename: 'PanelTranscript';
      panelId: string;
      transcriptId: string;
      parentPanelId?: string | null;
      targetRegionQty?: number | null;
      abundance?: number | null;
      refAbundance?: number | null;
      geneName?: string | null;
      transcriptomeId: string;
      tissueId: string;
      userSpecifiedName: string;
      isLocked?: boolean | null;
      status: PanelTranscriptStatus;
      source: PanelTranscriptSource;
      statusMessage?: string | null;
      comment?: string | null;
      type: PanelTranscriptType;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      isIncluded?: boolean | null;
      auxProbes?: PanelTranscriptSequentialAuxProbes | null;
      geneId?: string | null;
      synonyms?: Array<{
        __typename: 'GeneSymbolSynonym';
        geneSymbol: string;
        score: number;
        status: PanelTranscriptStatus;
        targetRegionQty?: number | null;
      }> | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListQuotesQueryVariables = {
  quoteId?: string | null;
  filter?: ModelQuoteFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListQuotesQuery = {
  listQuotes?: {
    __typename: 'ModelQuoteConnection';
    items: Array<{
      __typename: 'Quote';
      quoteId: string;
      totalGenes?: number | null;
      totalExtras?: number | null;
      Status: QuoteStatus;
      statusChangedAt?: string | null;
      trackingNumber?: string | null;
      deliveryAddress?: string | null;
      billingAddress?: string | null;
      comment?: string | null;
      totalPrice?: number | null;
      userId: string;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      quotepositions?: {
        __typename: 'ModelQuotePositionConnection';
        items: Array<{
          __typename: 'QuotePosition';
          panel?: {
            __typename: 'Panel';
            panelId: string;
            panelName: string;
            ownershipType?: PanelOwnershipType | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdatePanelTranscriptMutationVariables = {
  input: UpdatePanelTranscriptInput;
  condition?: ModelPanelTranscriptConditionInput | null;
};

export type UpdatePanelTranscriptMutation = {
  updatePanelTranscript?: {
    __typename: 'PanelTranscript';
    panelId: string;
    transcriptId: string;
    parentPanelId?: string | null;
    targetRegionQty?: number | null;
    abundance?: number | null;
    geneName?: string | null;
    transcriptomeId: string;
    tissueId: string;
    userSpecifiedName: string;
    isIncluded?: boolean | null;
    status: PanelTranscriptStatus;
    statusMessage?: string | null;
    source: PanelTranscriptSource;
    comment?: string | null;
    type: PanelTranscriptType;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    auxProbes?: PanelTranscriptSequentialAuxProbes | null;
  } | null;
};

export type DeletePanelTranscriptMutationVariables = {
  input: DeletePanelTranscriptInput;
  condition?: ModelPanelTranscriptConditionInput | null;
};

export type DeletePanelTranscriptMutation = {
  deletePanelTranscript?: {
    __typename: 'PanelTranscript';
    panelId: string;
    transcriptId: string;
  } | null;
};

export type ListExtrasQueryVariables = {
  extraType?: string | null;
};

export type ListExtrasQuery = {
  listExtras?: Array<{
    __typename: 'Extra';
    extraId: string;
    extraType: string;
    extraName: string;
    description?: string | null;
    isAntibodyKit: boolean;
    auxProbesUsed?: string | null;
  }> | null;
};

export type GetQuoteQueryVariables = {
  quoteId: string;
};

export type GetQuoteQuery = {
  getQuote?: {
    __typename: 'Quote';
    quoteId: string;
    totalGenes?: number | null;
    totalExtras?: number | null;
    Status: QuoteStatus;
    statusChangedAt?: string | null;
    trackingNumber?: string | null;
    deliveryAddress?: string | null;
    billingAddress?: string | null;
    comment?: string | null;
    totalPrice?: number | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    quotepositions?: {
      __typename: 'ModelQuotePositionConnection';
      items: Array<{
        __typename: 'QuotePosition';
        quotePositionId: string;
        panelId?: string | null;
        extraId?: string | null;
        quantity: number;
        cartridgeId?: string | null;
        createdAt: string;
        updatedAt: string;
        extra?: {
          __typename: 'Extra';
          extraId: string;
          extraType: string;
          extraName: string;
          description?: string | null;
          isAntibodyKit: boolean;
          auxProbesUsed?: string | null;
        } | null;
        panel?: {
          __typename: 'Panel';
          userId?: string | null;
          updatedAt: string;
          transcriptomeId: string;
          tissueId: string;
          statusMessage?: string | null;
          panelName: string;
          panelId: string;
          owner?: string | null;
          isCompleted?: boolean | null;
          hasCodebook?: boolean | null;
          createdAt: string;
          comment?: string | null;
          serialNumberTag?: string | null;
          isProprietary?: boolean | null;
          ownershipType?: PanelOwnershipType | null;
          genesSummary?: {
            __typename: 'PanelGenesSummary';
            totalNumber?: number | null;
            primary?: number | null;
          } | null;
          genes?: {
            __typename: 'ModelPanelTranscriptConnection';
            items: Array<{
              __typename: 'PanelTranscript';
              auxProbes?: PanelTranscriptSequentialAuxProbes | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
    } | null;
    feedback?: {
      __typename: 'ModelUserFeedbackConnection';
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateQuoteMutationVariables = {
  input: CreateQuoteInput;
  condition?: ModelQuoteConditionInput | null;
};

export type CreateQuoteMutation = {
  createQuote?: {
    __typename: 'Quote';
    quoteId: string;
    totalGenes?: number | null;
    totalExtras?: number | null;
    Status: QuoteStatus;
    statusChangedAt?: string | null;
    trackingNumber?: string | null;
    deliveryAddress?: string | null;
    billingAddress?: string | null;
    comment?: string | null;
    totalPrice?: number | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    quotepositions?: {
      __typename: 'ModelQuotePositionConnection';
      nextToken?: string | null;
    } | null;
    feedback?: {
      __typename: 'ModelUserFeedbackConnection';
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateQuotePositionMutationVariables = {
  input: CreateQuotePositionInput;
  condition?: ModelQuotePositionConditionInput | null;
};

export type CreateQuotePositionMutation = {
  createQuotePosition?: {
    __typename: 'QuotePosition';
    quoteId: string;
    quotePositionId: string;
    panelId?: string | null;
    extraId?: string | null;
    quantity: number;
    cartridgeId?: string | null;
    listPricePerItem: number;
    pricePerPosition: number;
    createdAt: string;
    updatedAt: string;
    panel?: {
      __typename: 'Panel';
      panelId: string;
      tissueId: string;
      transcriptomeId: string;
      userId?: string | null;
      panelName: string;
      isCompleted?: boolean | null;
      statusMessage?: string | null;
      comment?: string | null;
      hasCodebook?: boolean | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ownershipType?: PanelOwnershipType | null;
    } | null;
    quote?: {
      __typename: 'Quote';
      quoteId: string;
      totalGenes?: number | null;
      totalExtras?: number | null;
      Status: QuoteStatus;
      statusChangedAt?: string | null;
      trackingNumber?: string | null;
      deliveryAddress?: string | null;
      billingAddress?: string | null;
      comment?: string | null;
      totalPrice?: number | null;
      userId: string;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
    extra?: {
      __typename: 'Extra';
      extraId: string;
      extraType: string;
      extraName: string;
      description?: string | null;
    } | null;
    owner?: string | null;
    cartridge?: {
      __typename: 'Cartridge';
      cartridgeId: string;
      barcode: string;
      description?: string | null;
      expirationDate: string;
      reagentCartridgeMapNumber: number;
      cartridgeLotNumber: number;
      isUsed: boolean;
      isAvailable: boolean;
      isCreated: boolean;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type PullCodebookQueryVariables = {
  panelId: string;
};

export type PullCodebookQuery = {
  pullCodebook: string;
};

export type FinalizePanelMutationVariables = {
  panelId: string;
};

export type FinalizePanelMutation = {
  finalizePanel?: string | null;
};

export type CreateAddressMutationVariables = {
  input: CreateAddressInput;
  condition?: ModelAddressConditionInput | null;
};

export type CreateAddressMutation = {
  createAddress?: {
    __typename: 'Address';
    addressId: string;
    address: string;
    addressType: AddressType;
    userId: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type ListAddresssQueryVariables = {
  addressId?: string | null;
  filter?: ModelAddressFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListAddresssQuery = {
  listAddresses?: {
    __typename: 'ModelAddressConnection';
    items: Array<{
      __typename: 'Address';
      addressId: string;
      address: string;
      addressType: AddressType;
      userId: string;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteAddressMutationVariables = {
  input: DeleteAddressInput;
  condition?: ModelAddressConditionInput | null;
};

export type DeleteAddressMutation = {
  deleteAddress?: {
    __typename: 'Address';
    addressId: string;
    address: string;
    addressType: AddressType;
    userId: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type ListFinalizedPanelsByTissueQueryVariables = {
  tissueId: string;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListFinalizedPanelsByTissueQuery = {
  listFinalizedPanelsByTissue?: {
    __typename: 'ModelPanelConnection';
    items?: Array<{
      __typename: 'Panel';
      panelId: string;
      tissueId: string;
      transcriptomeId: string;
      panelName: string;
      genesSummary?: {
        __typename: 'PanelGenesSummary';
        totalNumber?: number | null;
        primary?: number | null;
        sequential?: number | null;
        totalAbundance?: number | null;
      } | null;
      comment?: string | null;
      ownershipType?: PanelOwnershipType | null;
    }> | null;
    nextToken?: string | null;
  } | null;
};

export type AddPromotedPanelToPanelMutationVariables = {
  panelId: string;
  promotedPanelId: string;
};

export type AddPromotedPanelToPanelMutation = {
  addPromotedPanelToPanel: Array<{
    __typename: 'CreatePanelTranscriptBatch';
    input: Array<{
      __typename: 'PanelTranscriptCreateData';
      panelId: string;
      transcriptId: string;
      tissueId: string;
      transcriptomeId: string;
      geneName?: string | null;
      userSpecifiedName: string;
      status: PanelTranscriptStatus;
      targetRegionQty?: number | null;
      abundance?: number | null;
      comment?: string | null;
      type: PanelTranscriptType;
      source: PanelTranscriptSource;
      owner: string;
      createdAt: string;
      updatedAt: string;
    }>;
    run?: Array<{
      __typename: 'PanelTranscript';
      comment?: string | null;
      status: PanelTranscriptStatus;
      abundance?: number | null;
      statusMessage?: string | null;
      geneName?: string | null;
      type: PanelTranscriptType;
      targetRegionQty?: number | null;
      transcriptId: string;
      userSpecifiedName: string;
      isIncluded?: boolean | null;
      source: PanelTranscriptSource;
    }> | null;
  }>;
};

export type AddFinalizedPanelToPanelMutationVariables = {
  panelId: string;
  finalizedPanelId: string;
};

export type AddFinalizedPanelToPanelMutation = {
  addFinalizedPanelToPanel: Array<{
    __typename: 'CreatePanelTranscriptBatch';
    input: Array<{
      __typename: 'PanelTranscriptCreateData';
      panelId: string;
      transcriptId: string;
      tissueId: string;
      transcriptomeId: string;
      geneName?: string | null;
      userSpecifiedName: string;
      status: PanelTranscriptStatus;
      targetRegionQty?: number | null;
      abundance?: number | null;
      comment?: string | null;
      type: PanelTranscriptType;
      source: PanelTranscriptSource;
      owner: string;
      createdAt: string;
      updatedAt: string;
    }>;
    run?: Array<{
      __typename: 'PanelTranscript';
      comment?: string | null;
      status: PanelTranscriptStatus;
      abundance?: number | null;
      statusMessage?: string | null;
      geneName?: string | null;
      type: PanelTranscriptType;
      targetRegionQty?: number | null;
      transcriptId: string;
      userSpecifiedName: string;
      isIncluded?: boolean | null;
      source: PanelTranscriptSource;
      auxProbes?: PanelTranscriptSequentialAuxProbes | null;
    }> | null;
  }>;
};

export type CreateExogenousSequenceMutationVariables = {
  input: CreateExogenousSequenceInput;
  condition?: ModelExogenousSequenceConditionInput | null;
};

export type CreateExogenousSequenceMutation = {
  createExogenousSequence?: {
    __typename: 'ExogenousSequence';
    id: string;
    name: string;
    sequence: string;
    fpkm: number;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListExogenousSequencesQueryVariables = {
  owner: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelExogenousSequenceFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListExogenousSequencesQuery = {
  listExogenousSequences?: {
    __typename: 'ModelExogenousSequenceConnection';
    items: Array<{
      __typename: 'ExogenousSequence';
      id: string;
      name: string;
      sequence: string;
      fpkm: number;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetPanelsByOwnerQueryVariables = {
  owner: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelPanelFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetPanelsByOwnerQuery = {
  listPanelsByOwner?: {
    __typename: 'ModelPanelConnection';
    items?: Array<{
      __typename: 'Panel';
      userId?: string | null;
      updatedAt: string;
      transcriptomeId: string;
      tissueId: string;
      panelName: string;
      panelId: string;
      owner?: string | null;
      isCompleted?: boolean | null;
      hasCodebook?: boolean | null;
      comment?: string | null;
      serialNumberTag?: string | null;
      isProprietary?: boolean | null;
      ownershipType?: PanelOwnershipType | null;
      genesSummary?: {
        __typename: 'PanelGenesSummary';
        totalNumber?: number | null;
        primary?: number | null;
      } | null;
    }> | null;
    nextToken?: string | null;
  } | null;
};

export type GetPanelsWithGenesByOwnerQueryVariables = {
  owner: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelPanelFilterInput | null;
  genesFilter?: ModelPanelTranscriptFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetPanelsWithGenesByOwnerQuery = {
  listPanelsByOwner?: {
    __typename: 'ModelPanelConnection';
    items?: Array<{
      __typename: 'Panel';
      userId?: string | null;
      updatedAt: string;
      transcriptomeId: string;
      tissueId: string;
      panelName: string;
      panelId: string;
      owner?: string | null;
      isCompleted?: boolean | null;
      hasCodebook?: boolean | null;
      comment?: string | null;
      serialNumberTag?: string | null;
      isProprietary?: boolean | null;
      ownershipType?: PanelOwnershipType | null;
      genesSummary?: {
        __typename: 'PanelGenesSummary';
        totalNumber?: number | null;
        primary?: number | null;
      } | null;
      genes?: {
        __typename: 'ModelPanelTranscriptConnection';
        items: Array<{
          __typename: 'PanelTranscript';
          auxProbes?: PanelTranscriptSequentialAuxProbes | null;
        } | null>;
      } | null;
    }> | null;
    nextToken?: string | null;
  } | null;
};

export type ListPromotedPanelsWithGenesQueryVariables = {
  limit?: number | null;
  nextToken?: string | null;
  tissueId?: string | null;
  genesFilter?: ModelPanelTranscriptFilterInput | null;
};

export type ListPromotedPanelsWithGenesQuery = {
  listPromotedPanels?: {
    __typename: 'ModelPanelConnection';
    items?: Array<{
      __typename: 'Panel';
      userId?: string | null;
      updatedAt: string;
      transcriptomeId: string;
      tissueId: string;
      panelName: string;
      panelId: string;
      owner?: string | null;
      isCompleted?: boolean | null;
      hasCodebook?: boolean | null;
      comment?: string | null;
      serialNumberTag?: string | null;
      isProprietary?: boolean | null;
      genesSummary?: {
        __typename: 'PanelGenesSummary';
        totalNumber?: number | null;
        primary?: number | null;
      } | null;
      ownershipType?: PanelOwnershipType | null;
      genes?: {
        __typename: 'ModelPanelTranscriptConnection';
        items: Array<{
          __typename: 'PanelTranscript';
          auxProbes?: PanelTranscriptSequentialAuxProbes | null;
        } | null>;
      } | null;
    }> | null;
    nextToken?: string | null;
  } | null;
};

export type ListPanelTranscriptsBySourceQueryVariables = {
  panelId: string;
  source?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelPanelTranscriptFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListPanelTranscriptsBySourceQuery = {
  listPanelTranscriptsBySource?: {
    __typename: 'ModelPanelTranscriptConnection';
    items: Array<{
      __typename: 'PanelTranscript';
      panelId: string;
      transcriptId: string;
      parentPanelId?: string | null;
      targetRegionQty?: number | null;
      abundance?: number | null;
      refAbundance?: number | null;
      geneName?: string | null;
      transcriptomeId: string;
      tissueId: string;
      userSpecifiedName: string;
      isLocked?: boolean | null;
      status: PanelTranscriptStatus;
      isIncluded?: boolean | null;
      statusMessage?: string | null;
      comment?: string | null;
      type: PanelTranscriptType;
      source: PanelTranscriptSource;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AddExogenousSequenceToPanelMutationVariables = {
  panelId: string;
  exogenousSequenceId: string;
};

export type AddExogenousSequenceToPanelMutation = {
  addExogenousSequenceToPanel?: {
    __typename: 'PanelTranscript';
    panelId: string;
    transcriptId: string;
    parentPanelId?: string | null;
    targetRegionQty?: number | null;
    abundance?: number | null;
    geneName?: string | null;
    transcriptomeId: string;
    tissueId: string;
    userSpecifiedName: string;
    status: PanelTranscriptStatus;
    isIncluded?: boolean | null;
    statusMessage?: string | null;
    comment?: string | null;
    type: PanelTranscriptType;
    source: PanelTranscriptSource;
    createdAt: string;
    updatedAt: string;
    geneId?: string | null;
    synonyms?: Array<{
      __typename: 'GeneSymbolSynonym';
      geneSymbol: string;
      score: number;
      status: PanelTranscriptStatus;
      targetRegionQty?: number | null;
    }> | null;
    transcript?: {
      __typename: 'Transcript';
      transcriptId: string;
      ensembleTranscriptId: string;
      ensembleTranscriptRev: number;
      transcriptomeId: string;
      geneId: string;
      geneName: string;
      geneBioType: string;
      geneIdRev: number;
      source: string;
      sequence: string;
      description?: string | null;
    } | null;
    transcriptTargetRegions?: {
      __typename: 'TranscriptTargetRegions';
      transcriptId: string;
      tissueId: string;
      transcriptomeId: string;
      fpkm?: number | null;
      targetRegionQty?: number | null;
      minimumGeneSpecificity?: number | null;
      minimumIsoformSpecificity?: number | null;
      minimumGC?: number | null;
      maximumGC?: number | null;
      minimumTm?: number | null;
      maximumTm?: number | null;
      overlap?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    panel?: {
      __typename: 'Panel';
      panelId: string;
      tissueId: string;
      transcriptomeId: string;
      userId?: string | null;
      panelName: string;
      isCompleted?: boolean | null;
      statusMessage?: string | null;
      comment?: string | null;
      hasCodebook?: boolean | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      serialNumberTag?: string | null;
      isProprietary?: boolean | null;
      ownershipType?: PanelOwnershipType | null;
    } | null;
    owner?: string | null;
  } | null;
};

export type SendEmailToUserMutationVariables = {
  subject: string;
  bodyHtml: string;
};

export type SendEmailToUserMutation = {
  sendEmailToUser?: string | null;
};

export type ListFileResourcesQueryVariables = {
  resourceId?: string | null;
  filter?: ModelFileResourceFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListFileResourcesQuery = {
  listFileResources?: {
    __typename: 'ModelFileResourceConnection';
    items: Array<{
      __typename: 'FileResource';
      resourceId: string;
      description: string;
      product: string;
      category: string;
      version: string;
      resourceDate: string;
      isFeatured: boolean;
      isNew: boolean;
      path: string;
      isHidden: boolean;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetUserPropsQueryVariables = {
  userId?: string | null;
};

export type GetUserPropsQuery = {
  getUserProps?: {
    __typename: 'UserProps';
    userId: string;
    isEnabled: boolean;
    email: string;
    emailVerified: boolean;
    accountStatus: string;
    name: string;
    familyName: string;
    countryId?: string | null;
    countryName?: string | null;
    regionId?: string | null;
    regionName?: string | null;
    userType: UserType;
    regionalDistributor?: {
      __typename: 'Distributor';
      distributorId: number;
      name: string;
      email: string;
    } | null;
    assignedDistributorId?: number | null;
    assignedDistributor?: {
      __typename: 'Distributor';
      distributorId: number;
      name: string;
      email: string;
    } | null;
    organization?: string | null;
    laboratory?: string | null;
  } | null;
};

export type UpdateUserPropsMutationVariables = {
  userProps: UserPropsInput;
};

export type UpdateUserPropsMutation = {
  updateUserProps?: {
    __typename: 'UserProps';
    userId: string;
    isEnabled: boolean;
    email: string;
    emailVerified: boolean;
    accountStatus: string;
    name: string;
    familyName: string;
    countryId?: string | null;
    countryName?: string | null;
    regionId?: string | null;
    regionName?: string | null;
    userType: UserType;
    organization?: string | null;
    laboratory?: string | null;
  } | null;
};

export type ListCountriesQueryVariables = {};

export type ListCountriesQuery = {
  listCountries?: Array<{
    __typename: 'Country';
    countryId: string;
    name: string;
    totalRegions: number;
  }> | null;
};

export type ListRegionsQueryVariables = {
  countryId: string;
};

export type ListRegionsQuery = {
  listRegions?: Array<{
    __typename: 'Region';
    countryId: string;
    regionId: string;
    country: {
      __typename: 'Country';
      countryId: string;
      name: string;
      totalRegions: number;
    };
    name: string;
  }> | null;
};

export type ApproveAccountMutationVariables = {
  approvalRequestId: string;
};

export type ApproveAccountMutation = {
  approveAccount: string;
};

export type DeleteGeneBlacklistItemMutationVariables = {
  id: string;
};

export type DeleteGeneBlacklistItemMutation = {
  deleteGeneBlacklistItem: boolean;
};

export type ListInstrumentsQueryVariables = {
  nextToken?: string | null;
};

export type ListInstrumentsQuery = {
  listInstruments?: {
    __typename: 'ModelInstrumentConnection';
    items?: Array<{
      __typename: 'Instrument';
      lastStatusUpdated: string;
      status: string;
      uniqueId: string;
      serialNumber: string;
      whoAmI: string;
      admins: Array<{
        __typename: 'UserProps';
        name: string;
        familyName: string;
        userId: string;
        email: string;
      }>;
      viewers: Array<{
        __typename: 'UserProps';
        userId: string;
        name: string;
        familyName: string;
        email: string;
      }>;
    }> | null;
    nextToken?: string | null;
  } | null;
};

export type UpdateUserInstrumentNotificationsMutationVariables = {
  instrumentUniqueId: string;
  events: Array<string>;
};

export type UpdateUserInstrumentNotificationsMutation = {
  updateUserInstrumentNotifications: Array<string>;
};

export type ListUserInstrumentNotificationsQueryVariables = {
  instrumentUniqueId: string;
};

export type ListUserInstrumentNotificationsQuery = {
  listUserInstrumentNotifications: Array<string>;
};

export type RemoveInstrumentUserMutationVariables = {
  input: InstrumentUserInput;
};

export type RemoveInstrumentUserMutation = {
  removeInstrumentUser?: {
    __typename: 'Instrument';
    lastStatusUpdated: string;
    status: string;
    uniqueId: string;
    serialNumber: string;
    whoAmI: string;
    admins: Array<{
      __typename: 'UserProps';
      name: string;
      familyName: string;
      userId: string;
      email: string;
    }>;
    viewers: Array<{
      __typename: 'UserProps';
      userId: string;
      name: string;
      familyName: string;
      email: string;
    }>;
  } | null;
};

export type AddInstrumentUserMutationVariables = {
  input: InstrumentUserInput;
};

export type AddInstrumentUserMutation = {
  addInstrumentUser?: {
    __typename: 'Instrument';
    lastStatusUpdated: string;
    status: string;
    uniqueId: string;
    serialNumber: string;
    whoAmI: string;
    admins: Array<{
      __typename: 'UserProps';
      name: string;
      familyName: string;
      userId: string;
      email: string;
    }>;
    viewers: Array<{
      __typename: 'UserProps';
      userId: string;
      name: string;
      familyName: string;
      email: string;
    }>;
  } | null;
};

export type GetCurrentGeneSetCollectionQueryVariables = {
  transcriptomeId?: string | null;
};

export type GetCurrentGeneSetCollectionQuery = {
  getCurrentGeneSetCollection?: {
    __typename: 'GeneSetCollection';
    geneSetS3Key?: string | null;
    geneSetVersion: string;
    geneSetCommitId: string;
  } | null;
};

export type GetPanelUserViewQueryVariables = {
  userId: string;
  panelId: string;
};

export type GetPanelUserViewQuery = {
  getPanelUserView?: {
    __typename: 'PanelUserView';
    geneSortMethod?: GeneSortMethod | null;
    geneSetSortMethod?: GeneSetSortMethod | null;
    geneSets?: Array<string> | null;
    geneSetS3Key?: string | null;
    geneSetVersion?: Array<string> | null;
    geneSetCommitId?: Array<string> | null;
  } | null;
};

export type CreatePanelUserViewMutationVariables = {
  input: CreatePanelUserViewInput;
  condition?: ModelPanelUserViewConditionInput | null;
};

export type CreatePanelUserViewMutation = {
  createPanelUserView?: {
    __typename: 'PanelUserView';
    geneSortMethod?: GeneSortMethod | null;
    geneSetSortMethod?: GeneSetSortMethod | null;
    geneSets?: Array<string> | null;
    geneSetS3Key?: string | null;
    geneSetVersion?: Array<string> | null;
    geneSetCommitId?: Array<string> | null;
  } | null;
};

export type UpdatePanelUserViewMutationVariables = {
  input: UpdatePanelUserViewInput;
  condition?: ModelPanelUserViewConditionInput | null;
};

export type UpdatePanelUserViewMutation = {
  updatePanelUserView?: {
    __typename: 'PanelUserView';
    geneSortMethod?: GeneSortMethod | null;
    geneSetSortMethod?: GeneSetSortMethod | null;
    geneSets?: Array<string> | null;
    geneSetS3Key?: string | null;
    geneSetVersion?: Array<string> | null;
    geneSetCommitId?: Array<string> | null;
  } | null;
};

export type DeletePanelTranscriptsBatchMutationVariables = {
  input: Array<DeletePanelTranscriptsBatchInput>;
};

export type DeletePanelTranscriptsBatchMutation = {
  DeletePanelTranscriptsBatch: Array<{
    __typename: 'DeletePanelTranscriptsBatchOutput';
    transcriptId: string;
  }>;
};

export type ListPromotedPanelsQueryVariables = {
  limit?: number | null;
  nextToken?: string | null;
  tissueId?: string | null;
};

export type ListPromotedPanelsQuery = {
  listPromotedPanels?: {
    __typename: 'ModelPanelConnection';
    items?: Array<{
      __typename: 'Panel';
      panelName: string;
      panelId: string;
      transcriptomeId: string;
      tissueId: string;
      comment?: string | null;
      updatedAt: string;
      serialNumberTag?: string | null;
      isCompleted?: boolean | null;
      genesSummary?: {
        __typename: 'PanelGenesSummary';
        totalNumber?: number | null;
        primary?: number | null;
        sequential?: number | null;
        totalAbundance?: number | null;
      } | null;
      ownershipType?: PanelOwnershipType | null;
      application?: string | null;
      platform?: string | null;
    }> | null;
    nextToken?: string | null;
  } | null;
};

export type GetPromotedPanelQueryVariables = {
  promotedPanelId: string;
};

export type GetPromotedPanelQuery = {
  getPromotedPanel?: {
    __typename: 'Panel';
    panelId: string;
    tissueId: string;
    transcriptomeId: string;
    userId?: string | null;
    panelName: string;
    isCompleted?: boolean | null;
    comment?: string | null;
    genesSummary?: {
      __typename: 'PanelGenesSummary';
      totalNumber?: number | null;
      primary?: number | null;
      sequential?: number | null;
      totalAbundance?: number | null;
    } | null;
    hasCodebook?: boolean | null;
    updatedAt: string;
    serialNumberTag?: string | null;
    isProprietary?: boolean | null;
    ownershipType?: PanelOwnershipType | null;
    application?: string | null;
    platform?: string | null;
  } | null;
};
