import {
  Flex,
  HStack,
  IconButton,
  useClipboard,
  useDisclosure
} from '@chakra-ui/react';
import { FC } from 'react';
import CopyIcon from './assets/copyIcon.svg';
import { theme } from '../../theme';
import { TooltipText } from '../TooltipText';
const { colors } = theme;

interface ClipboardCellProps {
  isDisabled?: boolean;
  value?: string;
  clipboardValue?: string;
  leftItem?: React.ReactNode;
}

export const ClipboardCell: FC<ClipboardCellProps> = ({
  isDisabled,
  value,
  clipboardValue,
  children,
  leftItem
}) => {
  const clipboardData = clipboardValue || value || '';
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { onCopy } = useClipboard(clipboardData);

  return (
    <Flex w="100%" bg={isDisabled ? 'background.secondary' : 'initial'}>
      <HStack
        w="100%"
        p="12px 8px 12px 12px"
        spacing="8px"
        isTruncated
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        {children ? (
          children
        ) : (
          <TooltipText
            textProps={{
              color: isDisabled ? 'content.additional' : 'content.secondary'
            }}
          >
            {value}
          </TooltipText>
        )}
        {isOpen && clipboardData && (
          <IconButton
            data-testid="copyButton"
            aria-label="Copy to clipboard"
            variant="primaryGhost"
            size="auto"
            _hover={{
              path: {
                stroke: colors.content.link.normal
              }
            }}
            icon={<CopyIcon />}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onCopy();
            }}
          />
        )}
      </HStack>
      {leftItem && <Flex p="12px 12px 12px 0">{leftItem}</Flex>}
    </Flex>
  );
};
